<template>
    <div class="main auto-height">
        <div class="xinxbox">
            <Row type="flex" justify="space-around" class="code-row-bg">
                <Col span="6" push="7" class-name="zixing" @click.native="shouye()">首页</Col>
                <Col span="6" push="3"   class-name="zixing">>专题</Col>
                <Col span="10"  class-name="zi">>专题列表</Col>
            </Row>
           
                <div class="golist">
                      <img src="../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                        <div class="ziti">{{plateName}}</div>
                        <div class="hengxian"></div>
                        <div class="tongzhi" v-for="(item,index) in gonggao" :key="index">
                            <div class="biaoti"  @click="yenei(item.subjectTitle,item.subjectResource,item.subjectContent,item.linkAddr,item.fileUrl,item.createTime,item.subjectType,item.videoUrl,item.fileName,item)">{{item.subjectTitle}} </div>
                            <div class="shijian">{{item.createTime }}</div>
                        </div>
               
                        <!-- 分页 -->
                    <el-pagination class="fenye"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[15]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount">
                    </el-pagination>
           
                </div>
              
            
        </div>
    </div>
</template>

<script>
import {querySubjectPlateContent,incrReadingNum} from "../../woke/xinx"
import { formatDate } from '../../commm/time'


export default {
   components:{},
    data(){
      
        return{
            totalCount:null,//总数据
            currentPage:1,//初始页
             pageSize: 15 ,//每页显示条目数
             gonggao:[],
             id:"",
             plateName:"",
        }
    },
    created(){
       this.plateName = this.$route.params.plateName
    },
   
    mounted() {
       this.querySubjectPlateContent()
    },
    activated(){
        
    },
    methods:{
         //改变分页的每页的页数
     handleSizeChange(size) {
        //  console.log(size);
       this.pageSize = size
        this.querySubjectPlateContent()
    //    console.log(this.pageSize) //每页下拉显示数据
     },
     // 改变分页的当前页面
     handleCurrentChange(currentPage) {
        //  console.log(currentPage);
       this.currentPage = currentPage
        this.querySubjectPlateContent()
    //    console.log(this.currentPage) //点击第几页
     },
        shouye(){
            this.$router.push("/index")
        },
    
         yenei(subjectTitle, subjectResource, subjectContent, linkAddr, fileUrl, createTime, subjectType, videoUrl, fileName,item) {
            // console.log(subjectTitle, subjectResource, subjectContent, linkAddr, fileUrl, createTime, subjectType, videoUrl, fileName);
            console.log(item.id);
            incrReadingNum({
                id:item.id,
                type:1
            }).then(res=>{
                console.log(res);
            })
            if (subjectType == 1) {
                // console.log(11);
                this.$router.push({
                    name: "projecttwo",
                    params: {
                        tit: subjectTitle,
                        subjectResource: subjectResource,
                        subjectContent: subjectContent,
                        linkAddr: linkAddr,
                        fileUrl: fileUrl,
                        time: createTime,
                        videoUrl: videoUrl,
                        fileName: fileName
                    },
                });
            } else if (subjectType == 2) {
                window.open(linkAddr, "_blank");
            }
        },
     querySubjectPlateContent(){
          this.id=this.$route.query.id
        //   console.log(this.id);
          querySubjectPlateContent({
              id:this.id,
              curPage:this.currentPage,
              maxPage:this.pageSize
          } ).then(res=>{
            //   console.log(res,"专题列表");
              this.gonggao = res.data.data.data
              this.totalCount = res.data.data.total
            //   console.log(this.gonggao);
          })
      }

       
    },
     
    filters:{
       /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/ 
    formatDate(time) {
        var date = new Date(time);
        return formatDate(date, 'yyyy-MM-dd'); 
    },
    },
     watch: {
      
  },

}
</script>
<style scoped>
.auto-height{
    min-height:100vh;
    margin-bottom:-60px;
    box-sizing:border-box;
    padding-top:30px;
    padding-bottom:60px;
    margin-left: 30px;
}
.code-row-bg{
    width: 350px;
    margin-left: 390px;
    margin-bottom: 20px;
}
.zixing{
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
    cursor: pointer;
}
.zi{
     margin-top: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #304C87;
    line-height: 21px;
}
.el-menu-vertical-demo{
    margin-top: 40px;
    margin-left: 400px;
}
.hengxian{
     margin-top: 20px;
     width:859px;
     height:1px;
     background-color:#005CB0;
     overflow:hidden;
    /* margin-left: 100px; */
}
.ziti{
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2C3B5B;
    line-height: 24px;
    letter-spacing: 2px;
    display: inline-block;
    margin-left: 10px;
}
.tibiao{
    width: 10px;
    height: 16px;
    margin-top: -5px;
}
.tongzhi{
    width: 860px;
    display: flex;
    justify-content: space-between;
     margin-top: 5px;
    cursor: pointer;
}
.biaoti{
    width: 750px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #2C3B5B;
    line-height: 21px;
    overflow: hidden;
    text-overflow:ellipsis; 
    white-space: nowrap;
    margin-bottom: 10px;
}
.shijian{
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #808080;
    line-height: 19px;
}
 .fenye{
     width: 600px;
     margin-top: 50px;
     margin-left: 418px;
 }
.golist{
    margin-left: 500px;
}
</style>